<template>
  <!-- eslint-disable max-len -->
  <section class="Hero">
    <div class="Hero-Slider">
      <VueSlider v-bind="options">
        <div class="Hero-Item">
          <picture class="Hero-Image">
            <source media="(max-width: 460px)" :srcset="require('@/assets/img/hero-bg-joker_460.png')">
            <source media="(max-width: 589px)" :srcset="require('@/assets/img/hero-bg-joker_590.png')">
            <source media="(max-width: 768px)" :srcset="require('@/assets/img/hero-bg-joker_768.png')">
            <source media="(max-width: 960px)" :srcset="require('@/assets/img/hero-bg-joker_960.png')">
            <source media="(max-width: 1248px)" :srcset="require('@/assets/img/hero-bg-joker_1248.png')">
            <img srcset="@/assets/img/hero-bg-joker_1920.png" loading="lazy" alt="">
          </picture>
          <div class="Hero-Content">
            <div class="Title Title--type-h1 Hero-Title" v-html="$t('homepage.hero.banner1.title')">
            </div>
            <p v-if="$i18n.locale !== 'se'" class="Hero-Text" v-html="$t('homepage.hero.banner1.text')">
            </p>
            <button v-if="$i18n.locale !== 'se'" class="Btn Btn--color Hero-Btn" @click="openRegistration()">
              {{ $t('buttons.deposit') }}
            </button>
            <button v-if="$i18n.locale === 'se'" class="Btn Btn--color Hero-Btn" @click="openRegistration()">
              {{ $t('menu.register') }}
            </button>
            <p v-if="$i18n.locale !== 'se'" class="Hero-AdditionalText" v-html="getBonusPolicy" />
          </div>
        </div>
<!--        <div class="Hero-Item">-->
<!--          <div class="Hero-Image">-->
<!--            <picture class="Hero-Image">-->
<!--              <source media="(max-width: 460px)" :srcset="require('@/assets/img/hero-bg-wolf_460.png')">-->
<!--              <source media="(max-width: 589px)" :srcset="require('@/assets/img/hero-bg-wolf_590.png')">-->
<!--              <source media="(max-width: 768px)" :srcset="require('@/assets/img/hero-bg-wolf_768.png')">-->
<!--              <img srcset="@/assets/img/hero-bg-wolf_1248.png" loading="lazy" alt="">-->
<!--            </picture>-->
<!--          </div>-->
<!--          <div class="Hero-Content">-->
<!--            <div class="Title Title&#45;&#45;type-h1 Hero-Title" v-html="$t('homepage.hero.banner1.title')">-->
<!--            </div>-->
<!--            <p class="Hero-Text" v-html="$t('homepage.hero.banner1.text')">-->
<!--            </p>-->
<!--            <button class="Btn Btn&#45;&#45;color Hero-Btn" @click="openLogin()">-->
<!--              Deposit now-->
<!--            </button>-->
<!--            <p class="Hero-AdditionalText">-->
<!--              {{ $t('homepage.hero.addText') }}-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
      </VueSlider>
    </div>
  </section>
</template>

<script>
import VueSlider from '@/components/Slider.vue';
import auth from '@/mixins/auth';

export default {
  name: 'Hero',
  components: {
    VueSlider,
  },
  mixins: [auth],
  data() {
    return {
      options: {
        items: 1,
        loop: true,
        dots: true,
      },
      slides: [
        {
          bg: 'hero-bg-wolf_1248.jpg',
          title: 'Number one place to have fun!',
          text: `Make first deposit now and get 100%<br/>
                up to <span class="Colored Hero--colored">€100 + 55 Free  Spins</span>`,
          btnText: 'Deposit now',
        },
        {
          bg: 'hero-bg-wolf_1248.jpg',
          title: 'Number one place to have fun!',
          text: `Make first deposit now and get 100%<br/>
                up to <span class="Colored Hero--colored">€100 + 55 Free  Spins</span>`,
          btnText: 'Deposit now',
        },
      ],
    };
  },
  computed: {
    getBonusPolicy() {
      const { href } = window.location;
      const hasSlash = href[href.length - 1] === '/';

      return this.$t('homepage.hero.addText', { path: hasSlash ? href : `${href}/` });
    },
  },
};
</script>

<style lang="scss">
.Hero {
  max-width: 100%;
  width: 100%;
  margin-top: -75px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;

  @media(min-width: $screen-s) {
    margin-bottom: 55px;
  }

  @media(min-width: $screen-l) {
    margin-bottom: 45px;
  }

  @media(min-width: $screen-xl) {
    margin-bottom: 5px;
  }

  &-Item {
    position: relative;
    height: 100%;
    overflow: hidden;

    @media(min-width: $screen-xs) {
      padding-bottom: 0px;
    }

    @media(min-width: $screen-xl) {
      height: 640px;
    }

    @media(min-width: $screen-xxl) {
      height: 800px;
    }
  }

  &-Content {
    position: relative;
    padding-top: 318px / 320px * 100%;
    text-align: center;

    @media(min-width: $screen-s) {
      position: absolute;
      top: 0;
      left: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding-top: 80px / 590px * 100%;
      text-align: left;
    }

    @media(min-width: $screen-m) {
      padding-top: 100px / 768px * 100%;
    }

    @media(min-width: $screen-xl) {
      left: calc(50% - 608px);
      padding-top: 150px;
    }
  }

  &-Image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    @media(min-width: $screen-s) {
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-Title {
    @media(min-width: $screen-m) {
      margin-bottom: 5px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 10px;
    }

    &+ .Hero-Btn {
      margin-top: 10px;

      @media(min-width: $screen-s) {
        margin-top: 35px;
      }
    }
  }

  &-Text {
    margin-bottom: 27px;
    font-size: 14px;
    line-height: 1.37;
    color: var(--color-text-main);

    @media(min-width: $screen-s) {
      margin-bottom: 15px;
      font-size: 14px;
    }

    @media(min-width: $screen-m) {
      font-size: 18px;
      margin-bottom: 25px;
    }

    @media(min-width: $screen-l) {
      margin-bottom: 35px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 40px;
      font-size: 22px;
    }
  }

  &-AdditionalText {
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 10px;
    color: var(--color-text-ghost);

    @media(min-width: $screen-s) {
      max-width: 80%;
      padding-left: 0;
      font-size: 9px;
    }

    @media(min-width: $screen-m) {
      max-width: 70%;
      font-size: 10px;
    }

    @media(min-width: $screen-l) {
      max-width: 60%;
      padding-bottom: 10px;
    }

    @media(min-width: $screen-xl) {
      max-width: 40%;
      padding-bottom: 20px;
    }

    @media(min-width: $screen-xxl) {
      max-width: 30%;
    }
  }

  &-Btn {
    padding: 20px 30px;
    font-size: 16px;

    @media(min-width: $screen-xl) {
      padding: 25px 40px;
      font-size: 18px;
    }
  }

  .v_slider__dots {
    display: none;

    @media(min-width: $screen-xl) {
      position: relative;
      margin-top: -85px;
    }
  }
}
</style>

<template>
  <!-- eslint-disable max-len -->
  <section class="BestGames">
    <div class="Title Title--type-h2 BestGames-Title">
      {{ $t('homepage.bestgames.title') }} <br/>{{ $t('prepositions.in') }} <span class="Colored">{{
        $t('title')
      }}</span>
    </div>
    <div class="BestGames-Text">
      {{ $t('homepage.bestgames.text') }}
    </div>
    <div class="BestGames-Header" v-click-outside="onClickOutside">
      <button
        class="Btn Btn--tab Btn--active BestGames-Tab BestGames-Tab--active BestGames-ChosenTab"
        :class="{'BestGames-ChosenTab--opened': listIsOpen}"
        @click="listIsOpen = !listIsOpen"
      >
        <svg
          class="Icon"
          :class="`BestGames-Icon--${tabActive.icon}`"
        >
          <use :xlink:href="require('@/assets/img/icon-sprite.svg') + `#${tabActive.icon}`"></use>
        </svg>
        {{ tabActive.name }}
        <i
          class="Arrow Tab-Arrow"
          :class="[ listIsOpen ? 'Arrow--up' : 'Arrow--down' ]"
        ></i>
      </button>
      <div v-if="width >= 768 || listIsOpen "
           class="BestGames-Tabs"
      >
        <button
          v-for="(tab, i) in tabs"
          :key="$t(tab.name)"
          class="Btn Btn--tab BestGames-Tab"
          :class="{'Btn--active BestGames-Tab--active': tabActive.name === tab.name}"
          @click="onChooseTab(i)"
        >
          <svg
            class="Icon"
            :class="`BestGames-Icon--${tab.icon}`"
          >
            <use :xlink:href="require('@/assets/img/icon-sprite.svg') + `#${tab.icon}`"></use>
          </svg>
          {{ $t(tab.name) }}
        </button>
      </div>
      <Search
        class="BestGames-Search"
        v-model="searchQuery"
        @search="searchGames(searchQuery)"
      />
    </div>
    <Loader v-if="gamesAreLoading"/>
    <div v-else-if="isNothingFound" class="TextMain TextMain--center">
      Sorry, we didn't find anything.
    </div>
    <template v-else>
      <div class="BestGames-Thumbs">
        <div
          v-for="(game, i) in gamesLimited(gamesShowed)"
          class="Thumb BestGames-Thumb"
          :key="i"
        >

          <div class="ThumbOverlay">
            <button class="ThumbOverlay-Link"
                    @click="openRegistration()"
            >
            </button>
            <button class="ThumbOverlay-Footer"
                    v-if="game.fun_mode"
                    @click="onStartGame(game)"
            >
              {{ $t('playForFun') }}
            </button>
          </div>

          <img
            :src="`https://aws-origin.image-tech-storage.com/gameRes/sq/${imageWidth}/${game.item_title}.jpg`"
            :alt="`${game.application_name}`"
            loading="lazy"
          />
        </div>
      </div>
      <div v-if="games.length > gamesShowed" class="BestGames-Btn">
        <button class="Btn Btn--outline Btn--outline2" @click="showMoreGames()">
          {{ $t('viewMoreGames') }}
        </button>
      </div>
    </template>
  </section>
</template>

<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import Search from '@/components/Search.vue';
import Loader from '@/components/Loader.vue';
import auth from '@/mixins/auth';
import GameIframe from '@/components/GameIframe.vue';

export default {
  name: 'BestGames',
  components: {
    Search,
    Loader,
  },
  mixins: [auth],
  data() {
    return {
      listIsOpen: false,
      tabActive: {
        name: 'categories.top',
        value: 'top',
        icon: 'top',
      },
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      gamesToShow: 24,
      gamesShowed: 24,
      searchQuery: '',
    };
  },
  computed: {
    ...mapState(['width', 'games', 'skin', 'gamesAreLoading', 'isNothingFound', 'gameScript']),
    ...mapGetters(['gamesLimited']),
    imageWidth() {
      if (this.width > 460 && this.width < 590) return 250;
      if (this.width > 960) return 200;
      return 150;
    },
    tabs() {
      if (this.$i18n.locale === 'de') {
        return [
          {
            name: 'categories.top',
            value: 'top',
            icon: 'top',
          },
          {
            name: 'categories.new',
            value: 'new',
            icon: 'star',
          },
          {
            name: 'categories.slots',
            value: 'slots',
            icon: 'slots',
          },
        ];
      }
      return [
        {
          name: 'categories.top',
          value: 'top',
          icon: 'top',
        },
        {
          name: 'categories.new',
          value: 'new',
          icon: 'star',
        },
        {
          name: 'categories.roulette',
          value: 'roulette',
          icon: 'roulette',
        },
        {
          name: 'categories.card',
          value: 'cards',
          icon: 'cards',
        },
        {
          name: 'categories.live',
          value: 'live',
          icon: 'video',
        },
        {
          name: 'categories.slots',
          value: 'slots',
          icon: 'slots',
        },
      ];
    },
  },
  methods: {
    ...mapActions(['getGames', 'searchGames', 'startGame']),
    onChooseTab(i) {
      this.gamesShowed = this.gamesToShow;
      this.tabActive = this.tabs[i];
      this.getGames(this.makeQuery());
      if (this.width < 768) this.listIsOpen = false;
    },
    onClickOutside() {
      this.listIsOpen = false;
    },
    makeQuery() {
      let query = `appName=${this.skin}`;
      switch (this.tabActive.value) {
        case 'new':
          query += '&is_new=true';
          break;
        case 'roulette':
          query += '&categories=Roulette';
          break;
        case 'cards':
          query += '&categories=Card Games';
          break;
        case 'live':
          query += '&categories=Live Casino';
          break;
        case 'slots':
          query += '&categories=Slot';
          break;
        default:
          query += '&is_most_popular=true';
      }

      return query;
    },
    showMoreGames() {
      this.gamesShowed += this.gamesToShow;
    },
    onStartGame(game) {
      this.startGame(game.internal_game_id)
        .then(() => {
          const size = game.aspect.split('X');
          this.$modal.show(
            GameIframe,
            {
              script: this.gameScript,
              height: size[1],
            },
            {
              width: size[0],
              height: size[1],
              adaptive: true,
              scrollable: true,
            },
          );
        });
    },
  },
};
</script>

<style lang="scss">
.BestGames {
  margin-bottom: 90px;

  @media(min-width: $screen-l) {
    margin-bottom: 71px;
  }

  @media(min-width: $screen-xl) {
    margin-bottom: 100px;
  }

  &-Title {
    display: none;

    @media(min-width: $screen-m) {
      display: block;
      margin-bottom: 20px;
    }

    @media(min-width: $screen-s) {
      max-width: 60%;
      margin-bottom: 30px;
    }
  }

  &-Text {
    display: none;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 28px;
    color: var(--color-text-ghost);

    @media(min-width: $screen-m) {
      display: block;
      width: 50%;
      font-size: 14px;
    }

    @media(min-width: $screen-l) {
      margin-bottom: 20px;
    }

    @media(min-width: $screen-xl) {
      width: 41%;
    }
  }

  &-Header {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;

    @media(min-width: $screen-m) {
      flex-direction: row;
    }

    @media(min-width: $screen-l) {
      margin-bottom: 15px;
    }

    @media(min-width: $screen-s) {
      margin-bottom: 31px;
    }
  }

  &-Tabs {
    position: absolute;
    left: 0;
    top: 125px;
    display: flex;
    flex-direction: column;
    order: 2;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-body);
    border: 1px solid var(--color-border-ghost);
    border-top: none;
    border-radius: 0 0 8px 8px;
    z-index: 1;

    .BestGames-Tab--active {
      display: none;
    }

    @media(min-width: $screen-m) {
      position: relative;
      top: initial;
      left: initial;
      flex-direction: row;
      flex-grow: 0;
      order: 0;
      margin-right: 10px;
      border: none;

      .BestGames-Tab--active {
        display: inline-flex;
      }
    }
  }

  &-Search {
    order: 0;
    margin-bottom: 14px;

    @media(min-width: $screen-m) {
      flex-shrink: 0;
      margin-bottom: 0;
      z-index: 1;
    }
  }

  &-Tab {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    order: 2;
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 0;

    @media(min-width: $screen-m) {
      justify-content: center;
      margin-right: 7px;
      padding: 13px 3px;
      border: 1px solid var(--color-border-ghost);
      border-radius: 8px;
      white-space: nowrap;
    }

    @media(min-width: $screen-l) {
      padding: 20px 7px;
    }

    @media(min-width: $screen-xl) {
      justify-content: center;
      margin-right: 10px;
      padding: 20px 17px;
      white-space: nowrap;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      position: relative;

      .Arrow {
        position: absolute;
        top: 26px;
        right: 14px;
        padding: 3px;
        border-color: var(--color-text-ghost);

        @media(min-width: $screen-m) {
          display: none;
        }
      }
    }
  }

  &-ChosenTab {
    display: flex;
    order: 1;
    border: 1px solid var(--color-border-ghost);
    border-radius: 8px;
    z-index: 1;

    @media(min-width: $screen-m) {
      display: none;
    }

    &--opened {
      border-radius: 8px 8px 0 0;
    }
  }

  &-Icon {
    &--top {
      width: 22px;
      height: 22px;

      @media(min-width: $screen-m) {
        width: 17px;
        height: 17px;
      }

      @media(min-width: $screen-xl) {
        width: 22px;
        height: 22px;
      }
    }

    &--star {
      width: 20px;
      height: 18px;

      @media(min-width: $screen-m) {
        width: 16px;
        height: 14px;
      }

      @media(min-width: $screen-xl) {
        width: 20px;
        height: 18px;
      }
    }

    &--roulette {
      width: 20px;
      height: 20px;

      @media(min-width: $screen-m) {
        width: 16px;
        height: 16px;
      }

      @media(min-width: $screen-xl) {
        width: 22px;
        height: 22px;
      }
    }

    &--cards {
      width: 19px;
      height: 20px;

      @media(min-width: $screen-m) {
        width: 15px;
        height: 16px;
      }

      @media(min-width: $screen-xl) {
        width: 19px;
        height: 20px;
      }
    }

    &--video {
      width: 26px;
      height: 20px;

      @media(min-width: $screen-m) {
        width: 19px;
        height: 15px;
      }

      @media(min-width: $screen-xl) {
        width: 26px;
        height: 20px;
      }
    }

    &--slots {
      width: 31px;
      height: 20px;

      @media(min-width: $screen-m) {
        width: 23px;
        height: 15px;
      }

      @media(min-width: $screen-xl) {
        width: 31px;
        height: 20px;
      }
    }
  }

  &-Thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 26px;

    @media(min-width: $screen-s) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 45px;
    }
  }

  &-Thumb {
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }

    &:hover {
      .ThumbOverlay {
        height: 100%;
      }

      .ThumbOverlay-Link {
        display: block;
        height: 80px;

        @media (min-width: $screen-xs) {
          width: 120px;
          height: 120px;

          &:before {
            border: 27px solid transparent;
            border-left: 32px solid var(--color-text-main);
          }
        }

        @media (min-width: $screen-s) {
          width: 50px;
          height: 50px;

          &:before {
            border: 10px solid transparent;
            border-left: 15px solid var(--color-text-main);
          }
        }

        @media (min-width: $screen-m) {
          width: 65px;
          height: 65px;

          &:before {
            border: 17px solid transparent;
            border-left: 22px solid var(--color-text-main);
          }
        }

        @media (min-width: $screen-l) {
          width: 80px;
          height: 80px;

          &:before {
            border: 19px solid transparent;
            border-left: 24px solid var(--color-text-main);
          }
        }

        @media (min-width: $screen-xl) {
          width: 100px;
          height: 100px;

          &:before {
            border: 22px solid transparent;
            border-left: 27px solid var(--color-text-main);
          }
        }
      }

      .ThumbOverlay-Footer {
        display: flex;
      }
    }
  }

  &-Btn {
    text-align: center;

    .Btn {
      padding: 17px 20px;
      font-size: 12px;

      @media(min-width: $screen-l) {
        padding: 21px 27px;
        font-size: 14px;
      }

      @media(min-width: $screen-xl) {
        padding: 21px 35px;
        font-size: 14px;
      }
    }
  }
}

.ThumbOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  background-color: rgba(#242857, .7);
  transition: all 0.2s ease;
  overflow: hidden;
  border-radius: 8px;

  &-Link {
    display: none;
    align-self: center;
    top: 13%;
    transform: translate(0, 0);
    width: 80px;
    height: 0;
    background: linear-gradient(87.21deg, #FFA41D 1.01%, #FF8F22 48.07%, #FF7B27 99.21%);
    opacity: 1;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      border: 17px solid transparent;
      border-left: 22px solid var(--color-text-main);
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(0, -50%);
    }

    &:hover {
      opacity: 1;
    }

    @media (min-width: $screen-s) {
      width: 20px;
    }

    @media (min-width: $screen-xl) {
      opacity: .9;
    }
  }

  &-Footer {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25%;
    margin-top: auto;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-main);
    text-transform: uppercase;
    background: linear-gradient(87.21deg, #FFA41D 1.01%, #FF8F22 48.07%, #FF7B27 99.21%);
    opacity: .9;
    cursor: pointer;

    @media (min-width: $screen-xs) {
      font-size: 16px;
    }

    @media (min-width: $screen-s) {
      font-size: 8px;
    }

    @media (min-width: $screen-m) {
      font-size: 12px;
    }

    @media (min-width: $screen-l) {
      font-size: 14px;
    }

    @media (min-width: $screen-xl) {
      font-size: 16px;
    }
  }
}

.BestGames-Tab--disable {
  display: none;
}

</style>

<template>
  <div>
    <Hero/>
    <BestGames/>
    <Jackpots/>
    <Banner v-if="$i18n.locale !== 'se'" v-bind="getBanner1"/>
    <Tournaments v-if="$i18n.locale !== 'se'"/>
    <Promotions v-if="$i18n.locale !== 'se'"/>
    <!--    <Questions v-if="$i18n.locale !== 'se' && $i18n.locale !== 'dk'" />-->
    <Banner v-bind="getBanner2"/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import BestGames from '@/components/BestGames.vue';
import Jackpots from '@/components/Jackpots.vue';
import Banner from '@/components/Banner.vue';

const Tournaments = () => import('@/components/Tournaments.vue');
const Promotions = () => import('@/components/Promotions.vue');
// const Questions = () => import('@/components/Questions.vue');

export default {
  name: 'HomePage',
  components: {
    Hero,
    BestGames,
    Jackpots,
    Tournaments,
    Promotions,
    // Questions,
    Banner,
  },
  data() {
    return {
      banner1: {
        image460: 'banner1_460.png',
        image590: 'banner1_590.png',
        image768: 'banner1_768.png',
        image960: 'banner1_960.png',
        image1248: 'banner1_1248.png',
        image: 'banner1_1920.png',
      },
      banner2: {
        image460: 'banner2_460.png',
        image590: 'banner2_590.png',
        image768: 'banner2_768.png',
        image960: 'banner2_960.png',
        image1248: 'banner2_1248.png',
        image: 'banner2_1248.png',
        contain: true,
      },
    };
  },
  metaInfo() {
    return {
      title: this.$t('metaInfo.homePage.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.homePage.description'),
        },
      ],
    };
  },
  computed: {
    getBanner1() {
      const title = `${this.$t('homepage.banner1.title')}<br/> ${this.$t('prepositions.in')} <span class="Colored">${this.$t('title')}</span>`;
      const text = this.$t('homepage.banner1.text');
      const btnText = this.$t('buttons.play');

      return Object.assign(this.banner1, {
        title,
        text,
        btnText,
      });
    },
    getBanner2() {
      const title = this.$t('homepage.banner2.title');
      const bonus = this.$t('homepage.banner2.bonus');
      const text = this.$t('homepage.banner2.text');
      const btnText = this.$i18n.locale === 'se' ? this.$t('buttons.play') : this.$t('buttons.bonus');

      return Object.assign(this.banner2, {
        title,
        bonus,
        text,
        btnText,
      });
    },
  },
};
</script>

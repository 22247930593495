<template>
  <div ref="iframeWrapper" class="GameIframe"></div>
</template>

<script>
export default {
  name: 'GameIframe',
  props: {
    script: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '510',
    },
  },
  mounted() {
    const wrapper = this.$refs.iframeWrapper;
    const iframe = wrapper.appendChild(document.createElement('iframe'));
    iframe.width = '100%';
    iframe.height = this.height;
    iframe.contentWindow.document.open().write(this.script);
    iframe.contentWindow.document.close();
  },
};
</script>

<style lang="scss">
.GameIframe {
  width: 100%;
  height: 100%;
  background: var(--color-body);

  iframe {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

</style>
